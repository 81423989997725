<template>
  <div class="reports">
      <div class="reports-lists card">
        <img src="@/assets/img/laptop-bug.svg" alt="" />
        <h4>No se ah enviado ningun reporte</h4>
        <p>
          Envía reportes para empezar a generar puntos y así crecer en el
          programa de JAMA Security
        </p>
      </div>
      <div class="report-detail card">
        <img src="@/assets/img/laptop-bug.svg" alt="" />
        <h4>No se ah enviado ningun reporte</h4>
        <p>
          Aquí se mostrara el detalle de tu reporte
        </p>
      </div>
  </div>
</template>

<script>
export default {
    name: "Reports"
}
</script>

<style lang="scss">
.reports{
  padding: 60px;
  .card {
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 30px #00073614;
    margin: 0 0 30px 0;
    text-align: center;
    padding: 50px;
     img{
      width: 100px;
    }
  }
}
</style>